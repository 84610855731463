<div class="print-link">
  <a *ngIf="(refreshedVM !== null)" mat-button
  class="print-button"  (click)="printRecipePDF()"
  attr.aria-label="{{ 'RECIPE.CREATE.PRINT' | translate }}">
  <div class="print-container">
    <mat-icon class="icon print-icon" svgIcon="print"></mat-icon>
    <div>{{ 'RECIPE.CREATE.PRINT' | translate }}</div>
  </div>
</a>
</div>
  <div class="content no-print" *ngIf="(refreshedVM !== null)">
    <div class="flex recipe-image" style="flex-direction: column;">
      <div style="width:200px"></div>
      <img *ngFor="let imageRef of refreshedVM.orderedImageUriList" [src]="imageRef" alt="Recipe Image" />
    </div>
    <div class="flex" style="flex-direction: column; padding: 0 0px 0px 45px;">
      <h2>{{ refreshedVM.recipeName }}</h2>
      <div class="duration-info">
        <div class="duration-value">
          <h3>{{ 'RECIPE.CREATE.PREP_DETAILS.PREP_TIME' | translate }}</h3>
          {{ refreshedVM.formattedPrepTime }}
        </div>
        <div class="duration-value">
          <h3>{{ 'RECIPE.CREATE.PREP_DETAILS.COOK_TIME' | translate }}</h3>
          {{ refreshedVM.formattedCookTime }}
        </div>
      </div>
      <div id="printable-ingredients">
        <h3>
          {{ 'RECIPE.VOCABULARY.INGREDIENTS' | translate }}
        </h3>
        <table class="table_ingredient-table" aria-label="ingredient-list">
          <th id="printableRecipeIngredientsHeader"></th>
          <tr class="table-row-header_ingredients">
            <td > {{ 'RECIPE.VOCABULARY.ITEM_NUMBER' | translate }}</td>
            <td > {{ 'RECIPE.VOCABULARY.QUANTITY' | translate }}</td>
            <td > {{ 'RECIPE.VOCABULARY.DESCRIPTION' | translate }}</td>
          </tr>
          <hr>
          <tr class="table-row-value_ingredients" *ngFor="let ingredient of refreshedVM.ingredients">
            <td>{{  (ingredient.displayItem?.itemReference?.type === 'CUSTOM') ? ingredient.displayItem?.customItem?.supplierItemCode : ingredient.displayItem?.gfsItem?.id }}</td>
            <td >{{ ingredient.formattedMeasurement }}</td>
            <td>{{ ingredient.ingredientName }}</td>
          </tr>
        </table>
      </div>
      <div id="printable-preparation-instructions">
        <h3>
          {{ 'RECIPE.VOCABULARY.PREPARATION_INSTRUCTIONS' | translate }}
        </h3>
        <p style="white-space: pre ; word-wrap: break-word;
        width: 70%;
        text-wrap: wrap;"  [innerHTML]="refreshedVM.prepInstructions"></p>
      </div>
      <div class="profit-calculator-pricing">
        <div class="pricing-checkbox">
          <mat-checkbox 
          [checked]="printProfitCalculator"
          (change)="showCalculatorToggle($event)">
        </mat-checkbox>
      <h3> {{ 'RECIPE.PRICING.TITLE' | translate }}</h3>
        </div>
        <table *ngIf="refreshedVM.type === 'BATCHRECIPE'" class="table_calculator-table batch-recipe"  aria-label="profit-calculator">
        <th id="printableProfitCalculatorPricing"></th>
        <tr class="table-row-header_calculator">
          <td > {{ 'RECIPE.PRICING.TOTAL_FOOD_COST' | translate }} </td>
          <td > {{ 'RECIPE.CREATE.BATCH_YIELD' | translate }} </td>
          <td > {{ 'RECIPE.PRICING.COST_PER_UNIT' | translate : { value: refreshedVM.batchCostUnit} }}</td>
        </tr>
        <hr class="wd-l">
        <tr class="table-row-value_calculator">
          <td><gfs-localized-currency-value [value]="refreshedVM.recipeTotalCost">
          </gfs-localized-currency-value></td>
          <td> {{ refreshedVM.batchYieldQty }} {{ ' ' }} {{ refreshedVM.batchCostUnit }}</td>
          <td><gfs-localized-currency-value [value]="refreshedVM.recipeBatchPortionCost">
          </gfs-localized-currency-value></td>
        </tr>
        </table>
        <table *ngIf="refreshedVM.type === 'MENUITEMRECIPE'" class="table_calculator-table"  aria-label="profit-calculator">
        <th id="printableProfitCalculatorPricing"></th>
        <tr class="table-row-header_calculator">
          <td > {{ 'RECIPE.PRICING.MARGIN' | translate }} </td>
          <td > {{ 'RECIPE.PRICING.MENU_PRICE' | translate }}</td>
          <td > {{ 'RECIPE.PRICING.TOTAL_FOOD_COST' | translate }} </td>
          <td > {{ 'CATEGORY.RECIPE_CARD.FOOD_COST_PERCENTAGE' | translate }}</td>
        </tr>
        <hr class="wd-xl">
        <tr class="table-row-value_calculator">
          <td> <gfs-localized-currency-value [value]="refreshedVM.margin">
          </gfs-localized-currency-value></td>
          <td ><gfs-localized-currency-value [value]="refreshedVM.menuPrice">
          </gfs-localized-currency-value></td>
          <td><gfs-localized-currency-value [value]="refreshedVM.recipeTotalCost">
          </gfs-localized-currency-value></td>
          <td >{{ refreshedVM.foodCostPercentage }}{{ ' %' }}</td>
          </tr>
         </table>
    </div>
    </div>
  </div>
  <div id="recipe-print">
    <div class="content-container" style="display: flex; width:auto ; justify-content: center; font-family:sans-serif">
      <div class="content" style="display:flex;" *ngIf="(refreshedVM !== null)">
        <div class="flex recipe-image" style="display:flex;flex-direction: column;max-width: 200px;margin: 10px;">
          <div style="width:200px"></div>
          <img *ngFor="let imageRef of refreshedVM.orderedImageUriList" [src]="imageRef" alt="Recipe Image" />
        </div>
        <div class="flex" style="flex-direction: column; padding: 0 0px 0px 45px;">
          <h2>{{ refreshedVM.recipeName }}</h2>
          <div class="duration-info" 
          style="display: flex;
          flex-direction: row;
          justify-content: space-between;
          max-width: 440px;">
            <div class="duration-value">
              <h3>{{ 'RECIPE.CREATE.PREP_DETAILS.PREP_TIME' | translate }}</h3>
              {{ refreshedVM.formattedPrepTime }}
            </div>
            <div class="duration-value">
              <h3>{{ 'RECIPE.CREATE.PREP_DETAILS.COOK_TIME' | translate }}</h3>
              {{ refreshedVM.formattedCookTime }}
            </div>
          </div>
          <div id="printable-ingredients">
            <h3>
              {{ 'RECIPE.VOCABULARY.INGREDIENTS' | translate }}
            </h3>
            <table class="table_ingredient-table" aria-label="ingredient-list">
              <th id="printableRecipeIngredientsHeader"></th>
              <tr class="table-row-header_ingredients" style="font-size: 15px;
              font-weight: bold;">
                <td style="width: 151px;"> {{ 'RECIPE.VOCABULARY.ITEM_NUMBER' | translate }}</td>
                <td style="width: 151px;"> {{ 'RECIPE.VOCABULARY.QUANTITY' | translate }}</td>
                <td style="width: 151px;"> {{ 'RECIPE.VOCABULARY.DESCRIPTION' | translate }}</td>
              </tr>
              
              <tr class="table-row-value_ingredients" *ngFor="let ingredient of refreshedVM.ingredients">
                <td style="width: 151px;">{{  (ingredient.displayItem?.itemReference?.type === 'CUSTOM') ? ingredient.displayItem?.customItem?.supplierItemCode : ingredient.displayItem?.gfsItem?.id }}</td>
                <td style="width: 151px;">{{ ingredient.formattedMeasurement }}</td>
                <td style="width: 151px;">{{ ingredient.ingredientName }}</td>
              </tr>
            </table>
          </div>
          <div id="printable-preparation-instructions">
            <h3>
              {{ 'RECIPE.VOCABULARY.PREPARATION_INSTRUCTIONS' | translate }}
            </h3>
            <p style="white-space: pre ; word-wrap: break-word;
            width: 70%;
            text-wrap: wrap;" [innerHTML]="refreshedVM.prepInstructions"></p>
          </div>

          <div *ngIf="printProfitCalculator" class="profit-calculator-pricing" >
            
            <h3> {{ 'RECIPE.PRICING.TITLE' | translate }}</h3>
          <table *ngIf="refreshedVM.type === 'BATCHRECIPE'" class="table_calculator-table batch-recipe"  aria-label="profit-calculator">
            <th id="printableProfitCalculatorPricing"></th>
            <tr class="table-row-header_calculator" style="font-size: 15px;
            font-weight: bold;">
              <td style="width: 159px;"> {{ 'RECIPE.PRICING.TOTAL_FOOD_COST' | translate }} </td>
              <td style="width: 150px;"> {{ 'RECIPE.CREATE.BATCH_YIELD' | translate }} </td>
              <td style="width: 150px;"> {{ 'RECIPE.PRICING.COST_PER_UNIT' | translate : { value: refreshedVM.batchCostUnit} }}</td>
            </tr>
            <tr class="table-row-value_calculator">
              <td style="width: 159px;"><gfs-localized-currency-value [value]="refreshedVM.recipeTotalCost">
              </gfs-localized-currency-value></td>
              <td style="width: 150px;"> {{ refreshedVM.batchYieldQty }} {{ ' ' }} {{ refreshedVM.batchCostUnit }}</td>
              <td style="width: 150px;"><gfs-localized-currency-value [value]="refreshedVM.recipeBatchPortionCost">
              </gfs-localized-currency-value></td>
            </tr>
          </table>
          <table *ngIf="refreshedVM.type === 'MENUITEMRECIPE'" class="table_calculator-table"  aria-label="profit-calculator">
            <th id="printableProfitCalculatorPricing"></th>
            <tr class="table-row-header_calculator" style="font-size: 15px;
            font-weight: bold;">
              <td style="width: 100px;"> {{ 'RECIPE.PRICING.MARGIN' | translate }} </td>
              <td style="width: 120px;"> {{ 'RECIPE.PRICING.MENU_PRICE' | translate }}</td>
              <td style="width: 155px;"> {{ 'RECIPE.PRICING.TOTAL_FOOD_COST' | translate }} </td>
              <td style="width: 151px;"> {{ 'CATEGORY.RECIPE_CARD.FOOD_COST_PERCENTAGE' | translate }}</td>
            </tr>
            <tr class="table-row-value_calculator">
              <td style="width: 100px;"> <gfs-localized-currency-value [value]="refreshedVM.margin">
              </gfs-localized-currency-value></td>
              <td style="width: 120px;"><gfs-localized-currency-value [value]="refreshedVM.menuPrice">
              </gfs-localized-currency-value></td>
              <td style="width: 155px;"><gfs-localized-currency-value [value]="refreshedVM.recipeTotalCost">
              </gfs-localized-currency-value></td>
              <td style="width: 151px;">{{ refreshedVM.foodCostPercentage }}{{ ' %' }}</td>
            </tr>
          </table>
        </div>
        </div>
      </div>
    </div>
  </div>
  
